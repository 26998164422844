import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { AddIcon, CheckIcon } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import { type ArtistRecommendation } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { useFollowUnfollow } from '~app/hooks/use-follow-unfollow/use-follow-unfollow';
import { Play } from '~app/playback';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildArtistUrl } from '~app/utilities/urls';

import { type HomeLoaderData } from '../.server/loader';

const sectionTitle = 'Artists You Might Like';

export function Slide({
  artist,
  index,
  isFollowingStation,
  pageName,
  sectionPosition,
}: {
  artist: ArtistRecommendation;
  index: number;
  isFollowingStation: boolean;
  pageName: string;
  sectionPosition: number;
}) {
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();
  const { follow, unfollow, isFollowing } = useFollowUnfollow({
    contentId: String(artist?.contentId),
    contentType: Playback.StationType.Artist,
    contentName: artist?.content.name ?? '',
    section: slugify(sectionTitle),
    context: 'carousel',
    followed: isFollowingStation,
  });

  const { playing } = Play.useArtistPlay({
    context: 0,
    id: Number(artist?.contentId),
  });

  if (isBlank(artist)) {
    return null;
  }

  const artistUrl =
    artist.contentId && artist.content.name
      ? buildArtistUrl({
          id: artist.contentId,
          name: artist.content.name,
        })
      : undefined;

  const { src, srcSet } = getResponsiveImgAttributes(
    MediaServerURL.fromCatalog({
      type: 'artist',
      id: String(artist.contentId),
    }),
    {
      width: 275,
      ratio: [1, 1],
    },
  );

  return (
    <CardCarousel.Slide key={artist.contentId}>
      <ContentCard
        active={playing}
        button={
          isFollowing ? (
            <ContentCard.Button
              kind="primary"
              onClick={event => {
                event.stopPropagation();
                unfollow();
              }}
              type="submit"
            >
              <CheckIcon size="16" /> Following
            </ContentCard.Button>
          ) : (
            <ContentCard.Button
              onClick={event => {
                event.stopPropagation();
                follow();
              }}
              type="submit"
            >
              <AddIcon size="16" /> Follow
            </ContentCard.Button>
          )
        }
        image={
          <ContentCard.Image
            alt={artist.label ?? artist.content.name ?? ''}
            borderRadius="$999"
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Artist context={0} id={Number(artist?.contentId)} size={48} />
        }
        linesForTitle={2}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Artist}|${artist?.contentId}`,
                name: artist?.content.name ?? '',
              },
            },
          });
          return artistUrl && navigate(artistUrl);
        }}
        title={artist.label}
      />
    </CardCarousel.Slide>
  );
}

export function RecommendedArtistsCarousel({
  artists,
  pageName,
  sectionPosition,
}: {
  artists: Awaited<HomeLoaderData['recommendedArtists']>;
  pageName: string;
  sectionPosition: number;
}) {
  if (isBlank(artists)) {
    return null;
  }

  return (
    <CardCarousel kind="content" title={sectionTitle}>
      {artists.map((artist, index) => {
        return (
          <Slide
            artist={artist}
            index={index}
            isFollowingStation={artist.isFollowing}
            key={artist.content.id}
            pageName={pageName}
            sectionPosition={sectionPosition}
          />
        );
      })}
    </CardCarousel>
  );
}
