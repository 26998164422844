import type { MadeForYouRecs } from '~app/api/types';
import { useUser } from '~app/contexts/user';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { MadeForYouCarousel } from '~app/routes/_app/_index/components/made-for-you-carousel';
import { MadeForYouCTA } from '~app/routes/_app/_index/components/made-for-you-cta';

export const MadeForYou = (props: {
  playlists: MadeForYouRecs;
  pageName: string;
  sectionPosition: number;
}) => {
  const { playlists, pageName, sectionPosition } = props;

  const carouselBackgroundColor = useGetImageDominantColor(
    playlists?.[0]?.urls?.image,
  );

  const user = useUser();

  if (user?.isAnonymous) {
    return <MadeForYouCTA />;
  }

  return (
    <MadeForYouCarousel
      color={carouselBackgroundColor}
      pageName={pageName}
      playlists={playlists}
      sectionPosition={sectionPosition}
    />
  );
};
