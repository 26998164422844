import { Amp } from '@iheartradio/web.api/amp';
import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { media, useMediaQuery } from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import { type RecentlyPlayedResults } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback';
import { type LivePlayProps } from '~app/playback/controls/play/live-play';
import { AnalyticsContext } from '~app/utilities/constants';
import {
  buildArtistUrl,
  buildLiveUrl,
  buildPlaylistUrl,
  buildPodcastUrl,
} from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';
import { getRecentlyPlayedArtwork } from '~app/utilities/utilities';

type SlideProps = {
  station: RecentlyPlayedResults[number];
  imageSource?: string;
  pageName: string;
  index: number;
  sectionPosition: number;
};

const sectionTitle = 'Recently Played';

export function LiveSlide({
  station,
  imageSource,
  pageName,
  index,
  sectionPosition,
}: SlideProps) {
  const navigate = useNavigate();

  const { onItemSelected } = useItemSelected();

  const playProps: LivePlayProps = {
    context: 0,
    id: Number(station.id),
  };

  const { playing } = Play.useLivePlay(playProps);

  if (isBlank(station)) {
    return null;
  }

  const liveUrl = station.id ? buildLiveUrl({ ...station }) : undefined;

  const StationName = station.name ?? station.content?.[0]?.name ?? 'iHeart';

  const { src, srcSet } = getResponsiveImgAttributes(imageSource, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image alt={StationName} src={src} srcSet={srcSet} />
        }
        imageButton={<Play.Live {...playProps} size={48} />}
        linesForTitle={2}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Live}|${station?.id}`,
                name: StationName,
                type: Playback.StationType.Live,
              },
            },
          });
          return liveUrl && navigate(liveUrl);
        }}
        title={StationName}
      />
    </CardCarousel.Slide>
  );
}

export function ArtistSlide({
  station,
  pageName,
  index,
  sectionPosition,
}: SlideProps) {
  const navigate = useNavigate();

  const { onItemSelected } = useItemSelected();

  const { playing } = Play.useArtistPlay({
    context: 0,
    id: Number(station?.seedArtistId),
  });

  if (isBlank(station)) {
    return null;
  }

  const artistUrl =
    station.seedArtistId && station.name
      ? buildArtistUrl({
          id: station.seedArtistId,
          name: station.name,
        })
      : undefined;

  const { src, srcSet } = getResponsiveImgAttributes(
    MediaServerURL.fromCatalog({
      type: 'artist',
      id: String(station?.seedArtistId),
    }),
    {
      width: 275,
      ratio: [1, 1],
    },
  );

  return (
    <CardCarousel.Slide>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
            borderRadius="$999"
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Artist
            context={0}
            id={Number(station?.seedArtistId)}
            size={48}
          />
        }
        linesForTitle={2}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Artist}|${station?.seedArtistId}`,
                name: station?.name ?? '',
              },
            },
          });
          return artistUrl && navigate(artistUrl);
        }}
        title={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
      />
    </CardCarousel.Slide>
  );
}

export function PodcastSlide({
  station,
  imageSource,
  pageName,
  index,
  sectionPosition,
}: SlideProps) {
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();
  const { playing } = Play.usePodcastPlay({
    context: 0,
    id: Number(station.id),
  });

  if (isBlank(station)) {
    return null;
  }

  const podcastUrl = station.id
    ? buildPodcastUrl({
        podcastId: Number(station.id),
        slug: station.slug ?? station.content?.[0]?.name ?? '',
      })
    : undefined;

  const { src, srcSet } = getResponsiveImgAttributes(imageSource, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Podcast context={0} id={Number(station.id)} size={48} />
        }
        linesForTitle={2}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${station?.id}`,
                name: station?.name ?? '',
              },
            },
          });
          return podcastUrl && navigate(podcastUrl);
        }}
        title={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
      />
    </CardCarousel.Slide>
  );
}

export function PlaylistSlide({
  station,
  imageSource,
  pageName,
  index,
  sectionPosition,
}: SlideProps) {
  const navigate = useNavigate();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const { onItemSelected } = useItemSelected();

  const type = isPremium
    ? Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(station.ownerId)}::${station.playlistId}`,
    type,
  });

  const playlistAssets = playlistAnalyticsData({
    isCurated: true,
    playlistId: station.playlistId ?? '',
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: station.ownerId ?? '',
    playlistName: station.name ?? '',
    userType: user?.subscription.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  const { src, srcSet } = getResponsiveImgAttributes(imageSource, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(station.ownerId)}::${station.playlistId}`}
            size={48}
            type={type}
          />
        }
        linesForTitle={2}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: playlistAssets.asset,
            },
          });
          navigate(
            buildPlaylistUrl({
              name: station?.name ?? station.content?.[0]?.name ?? '',
              userId: station?.ownerId ?? '',
              id: station?.playlistId ?? '',
            }),
          );
        }}
        title={station.name ?? station.content?.[0]?.name ?? 'iHeart'}
      />
    </CardCarousel.Slide>
  );
}

export function FavoritesSlide({ station }: SlideProps) {
  const isLargeScreen = useMediaQuery(media.large);

  const { playing, onClick } = Play.useFavoritesPlay({
    context: 0,
    id: Number(station?.seedProfileId),
  });

  const alt = `${
    station.name ?? station.content?.[0]?.name ?? 'iHeart'
  } Favorites Radio`;

  const { src, srcSet } = getResponsiveImgAttributes(
    MediaServerURL.fromCatalog({
      type: 'favorites',
      id: String(station?.seedProfileId),
    }),
    {
      width: 275,
      ratio: [1, 1],
    },
  );

  return (
    <CardCarousel.Slide>
      <ContentCard
        active={playing}
        image={<ContentCard.Image alt={alt} src={src} srcSet={srcSet} />}
        imageButton={
          <Play.Favorites
            context={0}
            id={Number(station?.seedProfileId ?? 0)}
            size={48}
          />
        }
        linesForTitle={2}
        onClick={!isLargeScreen ? () => onClick() : () => {}}
        title={alt}
      />
    </CardCarousel.Slide>
  );
}

export const Slide = ({
  station,
  pageName,
  index,
  sectionPosition,
}: {
  station: RecentlyPlayedResults[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) => {
  const imageSource = getRecentlyPlayedArtwork(station);

  switch (station.stationType) {
    case Amp.StationEnum.LIVE: {
      return (
        <LiveSlide
          imageSource={imageSource}
          index={index}
          pageName={pageName}
          sectionPosition={sectionPosition}
          station={station}
        />
      );
    }

    case Amp.StationEnum.ARTIST: {
      return (
        <ArtistSlide
          index={index}
          pageName={pageName}
          sectionPosition={sectionPosition}
          station={station}
        />
      );
    }

    case Amp.StationEnum.PODCAST: {
      return (
        <PodcastSlide
          imageSource={imageSource}
          index={index}
          pageName={pageName}
          sectionPosition={sectionPosition}
          station={station}
        />
      );
    }

    case Amp.StationEnum.COLLECTION: {
      return (
        <PlaylistSlide
          imageSource={imageSource}
          index={index}
          pageName={pageName}
          sectionPosition={sectionPosition}
          station={station}
        />
      );
    }

    case Amp.StationEnum.FAVORITES: {
      return (
        <FavoritesSlide
          index={index}
          pageName={pageName}
          sectionPosition={sectionPosition}
          station={station}
        />
      );
    }

    default: {
      return null;
    }
  }
};

export const RecentlyPlayedCarousel = (props: {
  stations: RecentlyPlayedResults;
  sectionPosition: number;
  pageName: string;
}) => {
  const { stations, sectionPosition, pageName } = props;

  if (isBlank(stations)) {
    return null;
  }

  return (
    <CardCarousel kind="content" title={sectionTitle}>
      {stations?.map((station, index) => {
        return (
          <Slide
            index={index}
            key={index}
            pageName={pageName}
            sectionPosition={sectionPosition}
            station={station}
          />
        );
      })}
    </CardCarousel>
  );
};
