import { type Text, Box, Card, Link } from '@iheartradio/web.companion';
import {
  type ComponentProps,
  type MouseEvent,
  type MouseEventHandler,
  type ReactNode,
} from 'react';

export type FeatureCardProps = {
  active: boolean;
  children?: ReturnType<typeof Text> | ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  imageButton?: ReactNode;
  image: ReturnType<typeof Card.Image>;
  link?: ReturnType<typeof Link>;
  title?: ReactNode;
};

export const FeatureCard = (props: FeatureCardProps) => {
  const { active, image, children, title, link, imageButton, onClick } = props;

  return (
    <Box padding="$2 $2 $2 0">
      <Card.Root
        aspectRatio="21 / 9"
        cursor={onClick ? 'pointer' : undefined}
        data-test="feature-card"
        direction="horizontal"
        isActive={active}
        onClick={(event: MouseEvent<HTMLDivElement>) => {
          const target = event.target as HTMLDivElement;

          if (
            target instanceof HTMLAnchorElement ||
            target instanceof HTMLButtonElement
          ) {
            event.stopPropagation();
            return;
          }

          onClick?.(event);
        }}
        tabIndex={0}
        withBackground
      >
        <Card.ImageContainer
          aspectRatio="1 / 1"
          css={{
            borderStartEndRadius: 'unset',
            borderStartStartRadius: 'inherit',
            borderEndStartRadius: 'inherit',
            borderEndEndRadius: 'unset',

            [`& > *`]: {
              aspectRatio: '1 / 1',
              boxShadow: 'unset',
            },
          }}
        >
          {image}
          <Card.ImageButton
            css={{ right: '$8', bottom: '$8' }}
            onClick={(event: MouseEvent<HTMLDivElement>) =>
              event.stopPropagation()
            }
          >
            {imageButton}
          </Card.ImageButton>
        </Card.ImageContainer>
        <Card.Body aspectRatio="12 / 9" padding="$12" placeContent="center">
          <Card.Title
            color={active ? { light: '$red-600', dark: '$red-300' } : undefined}
            lines={{
              '@initial': 1,
              '@large': 2,
            }}
          >
            {title}
          </Card.Title>
          <Card.Subtitle
            lines={{
              '@initial': 1,
              '@medium': 2,
              '@large': 1,
            }}
          >
            <>
              {children}
              {link}
            </>
          </Card.Subtitle>
        </Card.Body>
      </Card.Root>
    </Box>
  );
};

FeatureCard.Link = Link;

FeatureCard.Image = function ContentCardImage(
  props: ComponentProps<typeof Card.Image>,
) {
  return <Card.Image aspectRatio="1 / 1" {...props} />;
};
