import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';
import { type NavigateFunction, useNavigate } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FeaturedPodcastsResult } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { FeatureCard } from '~app/components/feature-card/feature-card';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { Play } from '~app/playback';
import { AnalyticsContext } from '~app/utilities/constants';
import { getPodcastSlugFromWebLink } from '~app/utilities/slugs/get-podcast-profile-slug';
import { buildPodcastUrl } from '~app/utilities/urls';

const sectionTitle = 'Our Podcast Picks';

export const navigateToPodcast = (
  navigate: NavigateFunction,
  podcast?: FeaturedPodcastsResult[number] | null,
) => {
  if (isBlank(podcast)) return;
  const slug = getPodcastSlugFromWebLink(podcast?.link?.urls?.web);
  const podcastUrl = buildPodcastUrl({
    podcastId: podcast?.catalog?.id ?? '',
    slug,
  });
  if (isNotBlank(podcastUrl)) {
    navigate(podcastUrl);
  }
};

export function Slide({
  index,
  podcast,
  pageName,
  sectionPosition,
}: {
  index: number;
  podcast: FeaturedPodcastsResult[number];
  pageName: string;
  sectionPosition: number;
}) {
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();

  const { playing } = Play.usePodcastPlay({
    context: 0,
    id: Number(podcast?.catalog?.id),
  });

  const { src, srcSet } = getResponsiveImgAttributes(
    MediaServerURL.fromCatalog({
      type: 'podcast',
      id: String(podcast.catalog?.id),
    }),
    {
      width: 275,
      ratio: [1, 1],
    },
  );

  return (
    <CardCarousel.Slide key={podcast.id ?? podcast?.catalog?.id}>
      <FeatureCard
        active={playing}
        data-test="podcast-feature-card"
        image={
          <FeatureCard.Image
            alt={`Image for podcast: ${podcast.title}`}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Podcast
            context={0}
            id={Number(podcast?.catalog?.id)}
            size={48}
          />
        }
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${podcast?.catalog?.id}`,
                name: podcast?.title ?? '',
              },
            },
          });
          navigateToPodcast(navigate, podcast);
        }}
        title={podcast.title}
      >
        {podcast.subtitle}
      </FeatureCard>
    </CardCarousel.Slide>
  );
}

export function FeaturedPodcastsCarousel({
  podcasts,
  pageName,
  sectionPosition,
}: {
  podcasts: FeaturedPodcastsResult;
  color?: string;
  pageName: string;
  sectionPosition: number;
}) {
  const carouselBackgroundColor = useGetImageDominantColor(
    podcasts?.[0]?.img_uri,
  );

  if (isBlank(podcasts)) {
    return null;
  }

  return (
    <CardCarousel
      color={carouselBackgroundColor}
      kind="featured"
      title={sectionTitle}
    >
      {podcasts.map((podcast, index) => (
        <Slide
          index={index}
          key={podcast.id}
          pageName={pageName}
          podcast={podcast}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}
