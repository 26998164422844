import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import {
  type Key,
  media,
  SelectOption,
  Text,
  useMediaQuery,
} from '@iheartradio/web.companion';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import {
  useLocation,
  useNavigate,
  useNavigation,
  useSearchParams,
} from '@remix-run/react';
import { useCallback, useMemo, useState } from 'react';
import { isString } from 'remeda';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { ReducedPlaylistGenres } from '~app/api/get-playlist-genres.server';
import type { PlaylistsByGenre } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { SelectField } from '~app/components/select-field/select-field';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback';
import { type HomeLoaderData } from '~app/routes/_app/_index/.server/loader';
import {
  AnalyticsContext,
  delayInPopOverCloseForMobile,
} from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

export const sectionTitle = 'Playlists By Genre';

interface PlaylistGenreSelectProps {
  selectedGenre: Awaited<
    HomeLoaderData['playlistsByGenre']
  >['playlistGenres'][string];
  playlistGenres: Awaited<HomeLoaderData['playlistsByGenre']>['playlistGenres'];
  selectedGenreKey: string;
}

export function PlaylistGenreSelect(props: PlaylistGenreSelectProps) {
  const { playlistGenres, selectedGenreKey } = props;
  const [_searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const isMobileBreakpoint = useMediaQuery(media.largeMax);

  const items = useMemo(
    () =>
      Object.entries(playlistGenres).map(([genreId, { title: genre }]) => {
        return { key: genreId, label: genre, value: genre };
      }),
    [playlistGenres],
  );

  return (
    <Text
      alignItems="center"
      as="div"
      display="flex"
      flexWrap="wrap"
      gap="$8"
      kind={{ '@initial': 'h4', '@large': 'h3' }}
    >
      <Text as="h3" kind={{ '@initial': 'h4', '@large': 'h3' }}>
        {sectionTitle}
      </Text>
      <SelectField
        aria-label="Playlist Genre"
        defaultSelectedKey={selectedGenreKey}
        isOpen={isOpen}
        items={items}
        key={selectedGenreKey}
        name="genre"
        onBlur={() => setIsOpen(false)}
        // We want to add some delay in mobile to close select field so that it can't trigger the click event to below element rather than selecting different option
        onOpenChange={isOpen => {
          let delay = 0;
          if (isMobileBreakpoint && isOpen === false) {
            delay = delayInPopOverCloseForMobile;
          }
          setTimeout(() => {
            setIsOpen(isOpen);
          }, delay);
        }}
        onSelectionChange={useCallback(
          (key: Key) => {
            if (isString(key) && key !== selectedGenreKey) {
              setSearchParams(
                params => {
                  params.set('genre', key.toString());
                  return params;
                },
                { preventScrollReset: true },
              );
            }
          },
          [selectedGenreKey, setSearchParams],
        )}
        selectedKey={selectedGenreKey}
      >
        {item => {
          return (
            <SelectOption key={item.key} textValue={item.label}>
              {item.label}
            </SelectOption>
          );
        }}
      </SelectField>
    </Text>
  );
}

export function Slide({
  playlist,
  index,
  pageName,
  sectionPosition,
  section,
}: {
  playlist: Awaited<HomeLoaderData['playlistsByGenre']>['playlists'][number];
  index: number;
  pageName: string;
  sectionPosition: number;
  section: string;
}) {
  const [userId, id] = String(playlist.catalog?.id).split('::');
  const isPremium = isPremiumUser(useUser());
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();

  const type = isPremium
    ? Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const play = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(userId)}::${id}`,
    type,
  });

  const { src, srcSet } = getResponsiveImgAttributes(playlist?.img_uri, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide key={playlist.id}>
      <ContentCard
        active={play.playing}
        image={
          <ContentCard.Image
            alt={playlist.title ?? ''}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(userId)}::${id}`}
            size={48}
            type={type}
          />
        }
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(section),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${type}|${playlist.catalog?.id}`,
                name: playlist.title ?? '',
              },
            },
          });
          navigate(
            buildPlaylistUrl({
              name: playlist.title ?? '',
              userId,
              id,
            }),
          );
        }}
        subtitle={playlist.subtitle ?? ''}
        title={playlist.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

export function PlaylistsByGenreCarousel({
  playlistsByGenre,
  playlistGenres,
  pageName,
  sectionPosition,
  selectedPlaylistGenre,
}: {
  playlistsByGenre: PlaylistsByGenre;
  playlistGenres: ReducedPlaylistGenres;
  pageName: string;
  sectionPosition: number;
  selectedPlaylistGenre: string;
}) {
  const location = useLocation();
  const navigation = useNavigation();

  const selectedGenre = playlistGenres[selectedPlaylistGenre];

  return isBlank(playlistsByGenre) ? null : (
    <CardCarousel
      kind="content"
      loading={
        navigation.state === 'loading' &&
        navigation.location.pathname === location.pathname
      }
      title={
        <PlaylistGenreSelect
          playlistGenres={playlistGenres}
          selectedGenre={selectedGenre}
          selectedGenreKey={selectedPlaylistGenre}
        />
      }
    >
      {playlistsByGenre.map((playlist, index) => (
        <Slide
          index={index}
          key={playlist.catalog?.id}
          pageName={pageName}
          playlist={playlist}
          section={selectedGenre.title}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}
