import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, isNotBlank, slugify } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type {
  PopularPodcastsResults,
  RecommendedPodcastsResult,
} from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { Play } from '~app/playback';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPodcastUrl } from '~app/utilities/urls';

import { type HomeLoaderData } from '../.server/loader';

export type RecommendedPodcasts = Awaited<
  HomeLoaderData['recommendedPodcasts']
>;

function Slide({
  pageName,
  podcast,
  index,
  sectionPosition,
  section,
}: {
  pageName: string;
  podcast: RecommendedPodcasts[number];
  index: number;
  sectionPosition: number;
  section: string;
}) {
  const { onItemSelected } = useItemSelected();
  const navigate = useNavigate();

  const { playing } = Play.usePodcastPlay({
    id: Number(podcast.id),
    context: 0,
  });

  const podcastUrl = buildPodcastUrl({
    podcastId: Number(podcast.id),
    slug: podcast.slug,
  });

  const { src, srcSet } = getResponsiveImgAttributes(
    MediaServerURL.fromCatalog({ type: 'podcast', id: podcast.id }),
    {
      width: 275,
      ratio: [1, 1],
    },
  );

  return (
    <CardCarousel.Slide key={podcast.id}>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={podcast.title ?? ''}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Podcast context={0} id={Number(podcast.id)} size={48} />
        }
        onClick={() => {
          onItemSelected({
            pageName,
            section,
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Podcast}|${podcast.id}`,
                name: podcast.title ?? '',
                type: Playback.StationType.Podcast,
              },
            },
          });
          podcastUrl && navigate(podcastUrl);
        }}
        subtitle={podcast.description ?? ''}
        title={podcast.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

export function RecommendedPodcastsCarousel({
  recommendedPodcasts,
  pageName,
  popularPodcasts,
  sectionPosition,
}: {
  recommendedPodcasts: RecommendedPodcastsResult[];
  pageName: string;
  popularPodcasts: PopularPodcastsResults['podcasts'];
  sectionPosition: number;
}) {
  if (isBlank(recommendedPodcasts) && isBlank(popularPodcasts)) {
    console.log('non-poopie');
    return null;
  }

  let podcasts: RecommendedPodcasts = popularPodcasts;
  let carouselTitle = "Today's Top Podcasts";

  if (isNotBlank(recommendedPodcasts)) {
    podcasts = recommendedPodcasts;
    carouselTitle = 'Recommended Podcasts';
  }

  return (
    <CardCarousel kind="content" title={carouselTitle}>
      {podcasts.map((podcast, index) => (
        <Slide
          index={index}
          key={podcast.id}
          pageName={pageName}
          podcast={podcast}
          section={slugify(carouselTitle)}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}

export function RecommendedPodcastsCarouselFallback({
  pageName,
  popularPodcasts,
  sectionPosition,
}: {
  pageName: string;
  popularPodcasts: PopularPodcastsResults['podcasts'];
  sectionPosition: number;
}) {
  if (isBlank(popularPodcasts)) {
    return null;
  }

  const carouselTitle = "Today's Top Podcasts";

  return (
    <CardCarousel kind="content" title={carouselTitle}>
      {popularPodcasts.map((podcast, index) => (
        <Slide
          index={index}
          key={podcast.id}
          pageName={pageName}
          podcast={podcast}
          section={slugify(carouselTitle)}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}
