import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import type { FeaturedPlaylistsResult } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { useUser } from '~app/contexts/user';
import { useGetImageDominantColor } from '~app/hooks/use-get-image-dominant-color';
import { Play } from '~app/playback';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Featured Playlists';

function Slide({
  playlist,
  pageName,
  index,
  sectionPosition,
}: {
  playlist: FeaturedPlaylistsResult[number];
  pageName: string;
  index: number;
  sectionPosition: number;
}) {
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();
  const user = useUser();
  const isPremium = isPremiumUser(user);

  const [userId, id] = String(playlist.catalog?.id).split('::');
  const type = isPremium
    ? Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(userId)}::${id}`,
    type,
  });

  const { src, srcSet } = getResponsiveImgAttributes(playlist?.img_uri, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide key={playlist.id ?? playlist?.catalog?.id}>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={playlist.title ?? ''}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(userId)}::${id}`}
            size={48}
            type={type}
          />
        }
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${type}|${playlist.catalog?.id}`,
                name: playlist.title ?? '',
              },
            },
          });
          navigate(
            buildPlaylistUrl({
              name: playlist.title ?? '',
              userId,
              id,
            }),
          );
        }}
        subtitle={playlist.subtitle ?? ''}
        title={playlist.title ?? ''}
      />
    </CardCarousel.Slide>
  );
}

export function FeaturedPlaylistsCarousel({
  pageName,
  playlists,
  sectionPosition,
}: {
  pageName: string;
  playlists: FeaturedPlaylistsResult;
  sectionPosition: number;
}) {
  const carouselBackgroundColor = useGetImageDominantColor(
    playlists?.[0]?.img_uri,
  );

  if (isBlank(playlists)) {
    return null;
  }

  return (
    <CardCarousel
      color={carouselBackgroundColor}
      description="Explore latest releases, picked by iHeart for our dear listeners"
      kind="content"
      title={sectionTitle}
    >
      {playlists.map((playlist, index) => (
        <Slide
          index={index}
          key={playlist.id}
          pageName={pageName}
          playlist={playlist}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}
