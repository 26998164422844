import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { useFetcher, useNavigate } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import { type LiveStationsResults } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { ContentCard } from '~app/components/content-card/content-card';
import { MarketsDropdown } from '~app/components/markets-dropdown';
import { SET_MARKET_FETCHER_KEY } from '~app/hooks/use-set-market-id';
import { Play } from '~app/playback';
import { type LivePlayProps } from '~app/playback/controls/play/live-play';
import { useRootLoaderData } from '~app/root';
import { type HomeLoaderData } from '~app/routes/_app/_index/.server/loader';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildLiveUrl } from '~app/utilities/urls';

const sectionTitle = 'Live Radio in';

function Slide({
  liveStation,
  sectionPosition,
  index,
  pageName,
}: {
  liveStation: Awaited<HomeLoaderData['liveStations']>[number];
  sectionPosition: number;
  index: number;
  pageName: string;
}) {
  const { onItemSelected } = useItemSelected();
  const navigate = useNavigate();

  const playProps: LivePlayProps = {
    context: 0,
    id: Number(liveStation?.id),
  };

  const { playing } = Play.useLivePlay(playProps);

  if (isBlank(liveStation)) {
    return null;
  }

  const liveUrl = liveStation.id ? buildLiveUrl({ ...liveStation }) : undefined;

  const { src, srcSet } = getResponsiveImgAttributes(liveStation.logo, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide key={liveStation.id}>
      <ContentCard
        active={playing}
        image={
          <ContentCard.Image
            alt={liveStation.name ?? ''}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={<Play.Live {...playProps} size={48} />}
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: {
                id: `${Playback.StationType.Live}|${liveStation?.id}`,
                name: liveStation?.name ?? '',
                type: Playback.StationType.Live,
              },
            },
          });
          liveUrl && navigate(liveUrl);
        }}
        subtitle={liveStation.description}
        title={liveStation.name}
      />
    </CardCarousel.Slide>
  );
}

export const RecommendedLiveStationsCarousel = ({
  liveStations,
  sectionPosition,
  pageName,
}: {
  liveStations: LiveStationsResults;
  sectionPosition: number;
  pageName: string;
}) => {
  const { market } = useRootLoaderData();
  const fetcher = useFetcher({ key: SET_MARKET_FETCHER_KEY });

  if (isBlank(liveStations)) {
    return null;
  }

  return (
    <CardCarousel
      key={market.marketId}
      kind="content"
      loading={fetcher.state === 'loading'}
      title={<MarketsDropdown sectionTitle={sectionTitle} />}
    >
      {liveStations?.map((liveStation, index) => (
        <Slide
          index={index}
          key={liveStation?.id}
          liveStation={liveStation}
          pageName={pageName}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
};
