import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import { Card } from '@iheartradio/web.companion';
import { isBlank, slugify } from '@iheartradio/web.utilities';
import { Link as RouterLink } from '@remix-run/react';

import { useItemSelected } from '~app/analytics/use-item-selected';
import { CardCarousel } from '~app/components/card-carousel';
import { GenreCard } from '~app/components/genre-card/genre-card';
import { useConfig } from '~app/contexts/config';
import { AnalyticsContext } from '~app/utilities/constants';

import { type HomeLoaderData } from '../.server/loader';

export const PopularNewsCarousel = (props: {
  popularNewsItems: Awaited<HomeLoaderData['popularNewsItems']>;
  sectionPosition: number;
  pageName: string;
}) => {
  const { popularNewsItems, sectionPosition, pageName } = props;
  const config = useConfig();
  const { onItemSelected } = useItemSelected();

  if (isBlank(popularNewsItems)) {
    return null;
  }

  const sectionTitle = 'Popular News';

  return (
    <CardCarousel kind="news" title={sectionTitle}>
      {popularNewsItems.map(({ data: newsItem, type }, index) => {
        const { src, srcSet } = getResponsiveImgAttributes(
          newsItem.summary?.image,
          { width: 400, ratio: [16, 9] },
        );

        return type === 'PUBLISHING' ? (
          <CardCarousel.Slide key={newsItem.id}>
            <Card.Link
              as={RouterLink}
              target="_blank"
              to={`${config.urls.content}${newsItem.slug}`}
            >
              <GenreCard
                image={
                  <GenreCard.Image
                    active={false}
                    alt={newsItem.summary?.title}
                    src={src}
                    srcSet={srcSet}
                  />
                }
                onClick={() =>
                  onItemSelected({
                    pageName,
                    context: AnalyticsContext.Carousel,
                    sectionPosition,
                    itemPosition: index,
                    section: slugify(sectionTitle),
                    assets: {
                      asset: {
                        id: `news|${newsItem.id}`,
                        name: newsItem.summary?.title,
                      },
                    },
                  })
                }
                title={newsItem.summary?.title}
              />
            </Card.Link>
          </CardCarousel.Slide>
        ) : null;
      })}
    </CardCarousel>
  );
};
