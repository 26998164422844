import { getResponsiveImgAttributes } from '@iheartradio/web.assets';
import { Playback } from '@iheartradio/web.playback';
import { slugify } from '@iheartradio/web.utilities';
import { useNavigate } from '@remix-run/react';

import { playlistAnalyticsData } from '~app/analytics/playlist-analytics-helper';
import { useItemSelected } from '~app/analytics/use-item-selected';
import { type MadeForYouRecs } from '~app/api/types';
import { CardCarousel } from '~app/components/card-carousel';
import { FeatureCard } from '~app/components/feature-card/feature-card';
import { useUser } from '~app/contexts/user';
import { Play } from '~app/playback';
import { AnalyticsContext } from '~app/utilities/constants';
import { buildPlaylistUrl } from '~app/utilities/urls';
import { isAnonymousUser, isPremiumUser } from '~app/utilities/user';

const sectionTitle = 'Made For You';

function Slide({
  playlist,
  index,
  sectionPosition,
  pageName,
}: {
  playlist: MadeForYouRecs[number];
  index: number;
  sectionPosition: number;
  pageName: string;
}) {
  const navigate = useNavigate();
  const { onItemSelected } = useItemSelected();

  const user = useUser();
  const isPremium = isPremiumUser(user);

  const type = isPremium
    ? Playback.StationType.Playlist
    : Playback.StationType.PlaylistRadio;

  const { playing } = Play.usePlaylistPlay({
    context: 0,
    id: `${Number(playlist?.userId)}::${playlist?.id}`,
    type,
  });

  const playlistAssets = playlistAnalyticsData({
    isCurated: playlist.curated,
    playlistId: playlist.id,
    profileId: user?.profileId?.toString() ?? '',
    playlistUserId: playlist.userId,
    playlistName: playlist.name,
    userType: user?.subscription.type,
    isAnonymous: isAnonymousUser(user) ?? true,
    playlistType: type,
  });

  const { src, srcSet } = getResponsiveImgAttributes(playlist.urls?.image, {
    width: 275,
    ratio: [1, 1],
  });

  return (
    <CardCarousel.Slide>
      <FeatureCard
        active={playing}
        image={
          <FeatureCard.Image
            alt={`Image for playlist: ${playlist.name}`}
            src={src}
            srcSet={srcSet}
          />
        }
        imageButton={
          <Play.Playlist
            context={0}
            id={`${Number(playlist?.userId)}::${playlist?.id}`}
            size={48}
            type={type}
          />
        }
        onClick={() => {
          onItemSelected({
            pageName,
            section: slugify(sectionTitle),
            context: AnalyticsContext.Carousel,
            itemPosition: index,
            sectionPosition,
            assets: {
              asset: playlistAssets.asset,
            },
          });
          navigate(buildPlaylistUrl({ ...playlist }));
        }}
        title={playlist.name}
      >
        {playlist.description}
      </FeatureCard>
    </CardCarousel.Slide>
  );
}

export function MadeForYouCarousel({
  playlists,
  color,
  pageName,
  sectionPosition,
}: {
  playlists: MadeForYouRecs;
  color?: string;
  pageName: string;
  sectionPosition: number;
}) {
  if (playlists === undefined || playlists.length < 2) {
    return null;
  }

  return (
    <CardCarousel color={color} kind="featured" title={sectionTitle}>
      {playlists.map((playlist, index) => (
        <Slide
          index={index}
          key={playlist.id}
          pageName={pageName}
          playlist={playlist}
          sectionPosition={sectionPosition}
        />
      ))}
    </CardCarousel>
  );
}
