import {
  getResponsiveImgAttributes,
  MediaServerURL,
} from '@iheartradio/web.assets';
import { Box, Button, Flex, Image, Text } from '@iheartradio/web.companion';
import { Link as RouterLink } from '@remix-run/react';
import { route } from 'routes-gen';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

const images = [
  'https://i.iheart.com/v3/url/aHR0cHMlM0ElMkYlMkZ3ZWItc3RhdGljLnBhZ2VzLmloZWFydC5jb20lMkZpbWclMkZkZWZhdWx0X2Zhdm9yaXRlLnBuZw==',
  'https://i.iheart.com/v3/re/new_assets/622922797e7648c322b6ebe4',
];

export const MadeForYouCTA = () => {
  const { onAnalyticsRegGateOpen } = useRegGateEvent();

  return (
    <Flex
      direction="row"
      height="19.2rem"
      justifyContent="center"
      overflow="hidden"
      padding={{ '@initial': '$16', '@large': '$16 $32' }}
      position="relative"
      zIndex="$0"
    >
      <Box
        backgroundImage={`url('${MediaServerURL.fromURL(images[0])
          .scale(125)
          .quality(1)
          .toString()}')`}
        backgroundPosition="center"
        backgroundSize="cover"
        filter="blur(4rem)"
        height="100%"
        position="absolute"
        top="$0"
        width="100%"
      />
      <Box
        background={{
          light:
            'linear-gradient(to right, rgba(246, 248, 249, 100%), rgba(255, 255, 255, 50%), rgba(246, 248, 249, 100%))',
          dark: 'linear-gradient(to right, rgba(0, 0, 0, 90%), rgba(0, 0, 0, 60%), rgba(0, 0, 0, 90%))',
        }}
        height="100%"
        position="absolute"
        top="$0"
        width="100%"
        zIndex="$1"
      />
      <Flex
        alignItems="center"
        direction="row"
        gap={{ '@initial': '$24', '@large': '$64' }}
        justifyContent="center"
        zIndex="$2"
      >
        <Flex direction="column" gap="$16" justifyContent="center">
          <Flex direction="column" gap="$4" justifyContent="center">
            <Text as="h3" kind={{ '@initial': 'h4', '@large': 'h3' }} lines={2}>
              Made For You
            </Text>
            <Text kind="body-3" lines={3}>
              Save more content and unlock your personalized playlists
            </Text>
          </Flex>
          <Flex direction="row" gap="$12">
            <Button
              as={RouterLink}
              color="red"
              kind="primary"
              onClick={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
              to={route(Routes.Login)}
            >
              Log in
            </Button>
            <Button
              as={RouterLink}
              color={{ dark: 'white', light: 'gray' }}
              kind="secondary"
              onClick={() =>
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.MADE_FOR_YOU)
              }
              size="small"
              to={route(Routes.SignUp)}
            >
              Sign up
            </Button>
          </Flex>
        </Flex>
        <Flex
          css={{
            '& > *:not(:first-child)': {
              display: 'none',
            },

            '@xsmall': {
              aspectRatio: '1/1',
            },

            '@xlarge': {
              aspectRatio: 'unset',
              '& > *:not(:first-child)': {
                display: 'initial',
              },
            },
          }}
          direction="row"
          flexGrow="0"
          gap="$24"
          height="12.4rem"
        >
          <Image
            alt="Your Favorites Radio"
            aspectRatio="1 / 1"
            borderRadius="$6"
            height="auto"
            maxHeight="100%"
            {...(() => {
              const { src, srcSet } = getResponsiveImgAttributes(images[0], {
                width: 125,
                ratio: [1, 1],
              });
              return { src, srcSet };
            })()}
          />
          <Image
            alt="Your Weekly Mixtape"
            aspectRatio="1 / 1"
            borderRadius="$6"
            height="auto"
            maxHeight="100%"
            {...(() => {
              const { src, srcSet } = getResponsiveImgAttributes(images[1], {
                width: 125,
                ratio: [1, 1],
              });
              return { src, srcSet };
            })()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
